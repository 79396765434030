var bar = {
    "birreSpina": [
      {  "categoria": "birre chiare", "name": "heineken silver", "vol": "4,0" },
      {  "categoria": "", "name": "icnusa", "vol": "4,6"},
      {  "categoria": "", "name": "veldensteiner lager", "vol": "4,3" },
      {  "categoria": "", "name": "veldensteiner pils", "vol": "4,9" },
      {  "categoria":"", "name": "icnusa non filtrata", "vol": "5,0" },
      {  "categoria": "", "name": "lagunitas ipa", "vol": "6,2" },
      {  "categoria": "", "name": "veldensteiner weizen", "vol": "5,1" },
      {  "categoria": "", "name": "slalom strong", "vol": "9,0" },

      {  "categoria": "birre ambrate", "name": "bulldog strong ale", "vol": "7,1" },
      {  "categoria": "", "name": "mc farland red", "vol": "5,6" },
      {  "categoria": "", "name": "affligem rouge", "vol": "6,7" },

      {  "categoria": "birre scure", "name": "guinness", "vol": "4,2" }

  ],
  
    "birreBottiglia": [
        {  "categoria": "birre analcoliche", "name": "heineken 0.0", "vol": "0,03" },

        {  "categoria": "birre chiare", "name": "corona", "vol": "4,5" },
        {  "categoria": "", "name": "messina", "vol": "5,0" },
        {  "categoria": "", "name": "gloken hell", "vol": "5,0" },
        {  "categoria": "", "name": "eucharius", "vol": "4,9" },
        {  "categoria": "", "name": "icnusa non filtrata", "vol": "5,0" },
        {  "categoria": "", "name": "icnusa cruda", "vol": "4,9" },
        {  "categoria": "", "name": "entropia", "vol": "4,8" },

        {  "categoria": "birre senza glutine", "name": "iOi", "vol": "4,7" },
        {  "categoria": "", "name": "daura", "vol": "5,4" },

        {  "categoria": "weisse", "name": "hacker pschorr", "vol": "5,5" },
        {  "categoria": "", "name": "simon weissbier", "vol": "5,4" },
        {  "categoria": "", "name": "moretti la bianca", "vol": "5,0" },

        {  "categoria": "birre doppio malto", "name": "westmalle dubble", "vol": "6,5" },
        {  "categoria": "", "name": "gotha", "vol": "7,7" },
        {  "categoria": "", "name": "avanti marchi", "vol": "6,5" },


    ],
    "vini": [
        { "id": 1, "categoria": "vini rossi in caraffa", "name": "sangiovese", "descr": "Vigna del Fico Grande 'Nespoli' doc Emilia R." },
        { "id": 2, "categoria": "vini rossi in bottiglia", "name": "sangiovese", "descr": "Superiore Cru Prugneto 'Nespoli' doc Emilia R. " },
        { "id": 3, "categoria": "", "name": "lambrusco", "descr": "Secco ottocento Frizz." },
        { "id": 4, "categoria": "", "name": "lambrusco", "descr": "Secco ottocento Frizz." },
        { "id": 5, "categoria": "vini rossi al calice", "name": "lambrusco", "descr": "Secco ottocento Frizz." },
        { "id": 6, "categoria": "", "name": "lambrusco", "descr": "Secco ottocento Frizz." },
        { "id": 7, "categoria": "", "name": "lambrusco", "descr": "Secco ottocento Frizz." },
        { "id": 8, "categoria": "vini bianchi in caraffa", "name": "lambrusco", "descr": "Secco ottocento Frizz." },
        { "id": 9, "categoria": "", "name": "lambrusco", "descr": "Secco ottocento Frizz." },
    ],
    "bibite": [
        { "id": 1, "name": "pepsi cola", "descr" : "" },
        { "id": 2, "name": "the lipton limone", "descr" : "" },
        
    ],
    "amari": [
        { "id": 1, "name" : "sambuca" },
        { "id": 2, "name" : "averna" },
        { "id": 3, "name" : "sambuca" },
    ]
  }  