var menu = {
  "pastadisemola": [{
    "name": "maccheroncini dragon",
    "price": 10,
    "ingredienti": "maccheroncini, sugo, guanciale, vodka"
  },
  {
    "name": "maccheroncini al ragù pasticciati",
    "price": 10,
    "ingredienti": "maccheroncini, ragù, panna"
  },
  {
    "name": "pennette all'arrabbiata",
    "price": 11,
    "ingredienti": "penne, sugo di pomodoro, peperoncino"
  },
  {
    "name": "gramigna alla salsiccia",
    "price": 10,
    "ingredienti": "pasta"
  },
  {
    "name": "rigatoni alla matriciana",
    "price": 10,
    "ingredienti": "pasta"
  },
  {
    "name": "spaghetti pomodoro e basilico",
    "price": 10,
    "ingredienti": "pasta"
  },
  {
    "name": "spagnetti alla carbonara",
    "price": 10,
    "ingredienti": "pasta"
  },
  {
    "name": "spaghetti pomodoro e basilico",
    "price": 10,
    "ingredienti": "pasta"
  },
  {
    "name": "garganelli zucchine speck e panna",
    "price": 10,
    "ingredienti": "tagliatelle, cipolla, fagioli"
  },
  {
    "name": "penne alla vodka",
    "price": 10,
    "ingredienti": "pasta"
  }

  ],
  "pastafresca": [
  {
    "name": "tagliatelle alla romagnola",
    "price": 10,
    "ingredienti": "maccheroncini, ragù, panna"
  },
  {
    "name": "tagliatelle al ragù",
    "price": 10,
    "ingredienti": "penne, sugo di pomodoro, peperoncino"
  },
  {
    "name": "tortelloni burro e salvia",
    "price": 10,
    "ingredienti": "pasta"
  },
  {
    "name": "tortelloni burro e oro e gorgonzola",
    "price": 10,
    "ingredienti": "pasta"
  },
  {
    "name": "gnocchi al gorgonzola e noci",
    "price": 10,
    "ingredienti": "pasta"
  },
  {
    "name": "gnocchi alla sorrentina",
    "price": 10,
    "ingredienti": "pasta"
  },
  {
    "name": "tortellini goccia d'oro",
    "price": 10,
    "ingredienti": "spaghetti, uovo, guanciale"
  }

  ],

  "secondi": [{
    "name": "cotoletta alla Bolognese",
    "price": 14
  },
  {
    "name": "cotoletta alla milanese con patate",
    "price": 15
  },
  {
    "name": "wurstel con patate",
    "price": 15
  },
  {
    "name": "salsiccia con patate",
    "price": 15
  },
  {
    "name": "stufato salsiccia e fagioli",
    "price": 15
  },
  {
    "name": "paillard alla griglia con patate",
    "price": 15
  },
  {
    "name": "straccetti rucola e grana",
    "price": 15
  },
  {
    "name": "costata di manzo alla griglia",
    "price": 15
  },
  {
    "name": "costata all'aceto balsamico",
    "price": 15
  },
  {
    "name": "costata al pepe verde",
    "price": 15
  },
  {
    "name": "tagliata all'olio profumato",
    "price": 15
  },
  {
    "name": "tagliata all'olio profumato",
    "price": 15
  },
  {
    "name": "petto di pollo alla griglia",
    "price": 15
  },
  {
    "name": "petto di pollo con pomodorini e rucola",
    "price": 15
  },
  {
    "name": "straccetti di pollo con rucola e grana",
    "price": 15
  },
  {
    "name": "straccetti di pollo al pepe verde",
    "price": 15
  }
  ],

  "piattifreddi": [{
    "name": "caprese",
    "price": 10,
    "ingredienti": "maccheroncini, sugo, guanciale, vodka"
  },
  {
    "name": "carpaccio di bresaola",
    "price": 10,
    "ingredienti": "penne, sugo di pomodoro, peperoncino"
  },
  {
    "name": "bresaola sedano, funghi e grana",
    "price": 10,
    "ingredienti": "pasta"
  },
  {
    "name": "roast beef",
    "price": 10,
    "ingredienti": "pasta"
  },
  {
    "name": "roast beef con rucola e grana",
    "price": 10,
    "ingredienti": "pasta"
  },
  {
    "name": "prosciutto crudo e melone (stagionale)",
    "price": 10,
    "ingredienti": "pasta"
  }
  ],

  "contorni": [{
    "name": "spincaci con aglio e peperoncino",
    "price": 4
  },
  {
    "name": "fagiolini all'uccelletto",
    "price": 5
  },
  {
    "name": "fverdure alla griglia",
    "price": 5
  },
  {
    "name": "spinaci",
    "price": 5.50
  },
  {
    "name": "patate al forno",
    "price": 5.50
  },
  {
    "name": "pomodori gratinati",
    "price": 5.50
  }
  ],

  "hamburger": [{
    "name": "hamburger",
    "price": 7,
    "ingredienti": "burger 150g, cipolla"
  },
  {
    "name": "cheeseburger",
    "price": 7,
    "ingredienti": "burger 150g, formaggio fuso"
  },
  {
    "name": "dragon burger",
    "price": 6,
    "ingredienti": "burger 150g, cipolla, formaggio fuso, bacon, salsa bbq"
  },
  {
    "name": "dievel dragon",
    "price": 8,
    "ingredienti": "burger 150g, cipolla, salsa calabrese"
  },
  {
    "name": "big burger",
    "price": 6,
    "ingredienti": "doppio burger 150g, pomodoro, insalata"
  },
  {
    "name": "giant burger",
    "price": 8,
    "ingredienti": "burger 220g, pomodoro, insalata"
  },
  {
    "name": "chicken burger",
    "price": 8,
    "ingredienti": "cotoletta di pollo, pomodoro e insalata"
  }
  ],
  "panini": [{
    "name": "dragon dart",
    "price": 7,
    "ingredienti": "bresaola, mozzarella, rucola"
  },
  {
    "name": "red lion",
    "price": 7,
    "ingredienti": "maionese, prosciutto cotto, pomodoro, insalata, funghi trifolati"
  },
  {
    "name": "daily",
    "price": 6,
    "ingredienti": "pomodoro, mozzarella, sale, origano"
  },
  {
    "name": "snack forest",
    "price": 8,
    "ingredienti": "maionese, prosciutto cotto, funghi trifolati"
  },
  {
    "name": "strong",
    "price": 6,
    "ingredienti": "salame piccante, fontina, giardiniera, tabasco"
  },
  {
    "name": "jolly",
    "price": 8,
    "ingredienti": "maioese, tonno, cipolla"
  },
  {
    "name": "magic",
    "price": 8,
    "ingredienti": "maioese, pancetta, insalata"
  },
  {
    "name": "tawsman",
    "price": 8,
    "ingredienti": "salsa bernese, prosciutto crudo, mozzarella"
  }
  ],
  "piade": [{
    "name": "rucola, stracchino e prosciutto crudo",

  },
  {
    "name": "rucola e stracchino",

  },
  {
    "name": "prosciutto cotto e mozzarella",
  },
  {
    "name": "salame e fontina",
  },
  {
    "name": "salame piccante e fontina",
  },
  {
    "name": "prosciutto crudo e mozzarella",
  },
  {
    "name": "piada alla nutella",
  }
  ],
  "crostini": [{
    "name": "bruschetta",
    "ingredienti": "aglio, olio, sale"
  },
  {
    "name": "romana",
    "ingredienti": "aglio, olio, sale, pomodoro fresco"
  },
  {
    "name": "crostino 1",
    "ingredienti": "pomodoro, mozzarella, acciughe"
  },
  {
    "name": "crostino 2",
    "ingredienti": "quattro formaggi"
  },
  {
    "name": "crostino 3",
    "ingredienti": "misto"
  },
  {
    "name": "crostino 4",
    "ingredienti": "mozzarella, prosciutto crudo, rucola"
  },
  {
    "name": "crostino 5",
    "ingredienti": "pancetta, grana, aceto balsamico"
  },
  {
    "name": "crostino 6",
    "ingredienti": " gorgonzola, speck"
  }
  ],

}